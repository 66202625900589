import { IDeleteButtonCell, IInputCell, ICheckBoxCell, IDateSelectCell } from '@/components/cells/cellInterfaces';
import { IRoseAGGridColumn, IRechenzentrumSammlungStichworteUI } from '@rose/common-ui';
import { IStichwortParam, IRechenzentrumSammlungStichworte } from '@rose/types';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsDiversRechenzentrumColumnsDefs(): IRoseAGGridColumn<IRechenzentrumSammlungStichworteUI>[] {
  return [
    {
      headerName: 'Stichwort',
      field: 'stichwort',
      cellRenderer: 'StichwortCell',
      valueGetter: params =>
        ({ farbe: params.data?.farbe, name: params.data?.name, kuerzel: params.data?.kuerzel } as IStichwortParam),
      sortable: true,
      sort: 'asc',
      width: 180,
    },
    {
      headerName: 'AVA-Datum',
      headerComponent: 'HelpTooltipHead',
      headerComponentParams: {
        context: {
          tooltipText: 'Datum wann die AVA angefragt wurde. Es wird dann die oben genannte AVA-Gültigkeit aufaddiert.',
        },
      },
      field: 'ava',
      valueGetter: params => ({ value: params.data?.details.ava, key: params.data?.name }),
      sortable: false,
      cellRenderer: 'DateSelectCell',
      cellRendererParams: {
        context: {
          label: 'AVA-Datum',
          clicked(field: { key: string; value: Date }) {
            console.log('field', field);
            editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { ava: field.value } });
          },
        },
      } as IDateSelectCell,
      width: 180,
    },
    {
      headerName: 'AVA-OK',
      headerComponent: 'HelpTooltipHead',
      headerComponentParams: {
        context: {
          tooltipText: 'Wenn dieses Stichwort bedeutet, dass die AVA ok ist, dann hier ein Häkchen setzen',
        },
      },
      field: 'avaOK',
      valueGetter: params => ({ value: params.data?.details.avaOK, key: params.data?.name }),
      sortable: false,
      cellRenderer: 'CheckboxCell',
      cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { key: string; value: boolean }) {
            editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { avaOK: field.value } });
          },
        },
      } as ICheckBoxCell,
      width: 100,
    },
    {
      headerName: 'EWE-Datum',
      headerComponent: 'HelpTooltipHead',
      headerComponentParams: {
        context: {
          tooltipText: 'Datum wann die EWE unterschrieben wurde',
        },
      },
      field: 'ewe',
      valueGetter: params => ({ value: params.data?.details.ewe, key: params.data?.name }),
      sortable: false,
      cellRenderer: 'DateSelectCell',
      cellRendererParams: {
        context: {
          label: 'EWE-Datum',
          clicked(field: { key: string; value: Date }) {
            editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { ewe: field.value } });
          },
        },
      } as IDateSelectCell,
      width: 180,
    },
    {
      headerName: 'Spezial-Fall',
      headerComponent: 'HelpTooltipHead',
      headerComponentParams: {
        context: {
          tooltipText: 'Dieses Stichwort hat Vorrang und wird bevorzugt angezeigt',
        },
      },
      field: 'spezialFall',
      valueGetter: params => ({ value: params.data?.details.spezialFall, key: params.data?.name }),
      sortable: false,
      cellRenderer: 'CheckboxCell',
      cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { key: string; value: boolean }) {
            editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { spezialFall: field.value } });
          },
        },
      } as ICheckBoxCell<string>,
      width: 120,
    },
    {
      headerName: 'Spezial-Fall-Alarm',
      headerComponent: 'HelpTooltipHead',
      headerComponentParams: {
        context: {
          tooltipText: 'Soll dieses Stichwort einen Alarm auslösen?',
        },
      },
      field: 'spezialFallAlarm',
      valueGetter: params => ({ value: params.data?.details.spezialFallAlarm, key: params.data?.name }),
      sortable: false,
      cellRenderer: 'CheckboxCell',
      cellStyle: { display: 'flex', 'justify-content': 'center', 'align-items': 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { key: string; value: boolean }) {
            editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { spezialFallAlarm: field.value } });
          },
        },
      } as ICheckBoxCell<string>,
      width: 180,
    },
    {
      headerName: 'Spezial-Fall-Anzeige',
      headerComponent: 'HelpTooltipHead',
      headerComponentParams: {
        context: {
          tooltipText: 'Dieser Inhalt wird angezeigt wenn das Stichwort vergeben ist',
        },
      },
      field: 'spezialFallAnzeige',
      valueGetter: params => ({ value: params.data?.details.spezialFallAnzeige, key: params.data?.name }),
      sortable: false,
      cellRenderer: 'InputCell',
      cellRendererParams: {
        context: {
          clicked(field: { key: string; value: string }) {
            editSettingsStore.commit.setRZStichworteDetails({ [field.key]: { spezialFallAnzeige: field.value } });
          },
        },
      } as IInputCell,
      width: 270,
    },
    {
      headerName: '',
      resizable: false,
      flex: 1,
    },
    {
      headerName: '',
      field: 'actions',
      cellRenderer: 'DeleteButtonCell',
      width: 50,
      resizable: false,
      cellRendererParams: {
        context: {
          clicked(field: { row: IRechenzentrumSammlungStichworteUI }) {
            editSettingsStore.commit.deleteRzStichworte([field.row.name as keyof IRechenzentrumSammlungStichworte]);
          },
        },
      } as IDeleteButtonCell<IRechenzentrumSammlungStichworteUI>,
    },
  ];
}
