import { defineComponent } from 'vue';
import DeleteButtonCell from '@/components/cells/DeleteButtonCell.vue';
import { german } from '@rose/common-ui';
import { settingsDiversZusatzversicherungColumnsDefs } from '@/components/columns/settings/diversZusatzversicherung';
import StichwortChip from '@/components/StichwortChip.vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
export default defineComponent({
  components: {
    StichwortChip: StichwortChip
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      columnDefs: settingsDiversZusatzversicherungColumnsDefs(),
      components: {
        actionButtonsRenderer: DeleteButtonCell
      },
      stichwortToAdd: {
        stichwort: '',
        text: ''
      },
      showDeleteAllSelected: false
    };
  },
  computed: {
    stichwortToAddStichwort: {
      get: function get() {
        return this.stichwortToAdd.stichwort;
      },
      set: function set(value) {
        this.stichwortToAdd.stichwort = value;
      }
    },
    stichwortToAddText: {
      get: function get() {
        return this.stichwortToAdd.text;
      },
      set: function set(value) {
        this.stichwortToAdd.text = value;
      }
    },
    zvStichworteKeys: function zvStichworteKeys() {
      return editSettingsStore.getters.zvStichworteKeys;
    },
    zvStichworteAvailable: function zvStichworteAvailable() {
      return editSettingsStore.getters.zvStichworteAvailable;
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      rowHeight: 48,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true
      },
      rowSelection: 'single',
      suppressCellFocus: true
    };
  },
  created: function created() {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        autoHeight: true
      },
      suppressCellFocus: true,
      suppressRowHoverHighlight: true
    };
  },
  methods: {
    onCellClicked: function onCellClicked(event) {
      this.$logger.debug('onCellClicked', event);
    },
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    addZVStichwort: function addZVStichwort() {
      if (staticDataMetricsStore.state.params.stichworteMap) {
        var zvStichwort = Object.assign(Object.assign({}, staticDataMetricsStore.state.params.stichworteMap[this.stichwortToAddStichwort]), {
          text: this.stichwortToAddText
        });
        editSettingsStore.commit.addZvStichworte(zvStichwort);
      }

      this.stichwortToAdd.stichwort = '';
      this.stichwortToAdd.text = '';
    }
  }
});