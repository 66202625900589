import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  name: 'TestPatientenIds',
  data: function data() {
    return {
      loading: true
    };
  },
  computed: {
    testPatientenIds: {
      get: function get() {
        var _a;

        return ((_a = editSettingsStore.state.editSettings.datensammlung) === null || _a === void 0 ? void 0 : _a.testPatientenIds) || [];
      },
      set: function set(value) {
        editSettingsStore.commit.setTestPatientenIds(value);
      }
    }
  },
  beforeMount: function beforeMount() {
    this.loading = false;
  }
});