import _defineProperty from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { defineComponent } from 'vue';
import { BewertungSammlungTypValues, DatenschutzSammlungTypValues, ExtraSeitenStichwortSammlungTypValues } from '@rose/types';
import RechenzentrumStichworte from '@/components/settings/Datensammlung/RechenzentrumStichworte.vue';
import ZusatzversicherungTable from '@/components/tables/settings/Datensammlung/ZusatzversicherungTable.vue';
import { authStore } from '@/state/authStore';
import Rechenzentrum from '@/components/settings/Datensammlung/Rechenzentrum.vue';
import RechenzentrumExtraSeiten from '@/components/settings/Datensammlung/RechenzentrumExtraSeiten.vue';
import RechenzentrumFactoring from '@/components/settings/Datensammlung/RechenzentrumFactoring.vue';
import StichwortChip from '@/components/StichwortChip.vue';
import { rules } from '@rose/common-ui';
import { editSettingsRootStore } from '@/state/settings/editSettingsRootStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import Zimmerbelegung from '@/components/settings/Datensammlung/Zimmerbelegung.vue';
import TestPatientenIds from '@/components/settings/Datensammlung/TestPatientenIds.vue';
export default defineComponent({
  components: {
    Zimmerbelegung: Zimmerbelegung,
    RechenzentrumStichworte: RechenzentrumStichworte,
    ZusatzversicherungTable: ZusatzversicherungTable,
    Rechenzentrum: Rechenzentrum,
    RechenzentrumExtraSeiten: RechenzentrumExtraSeiten,
    RechenzentrumFactoring: RechenzentrumFactoring,
    StichwortChip: StichwortChip,
    TestPatientenIds: TestPatientenIds
  },
  data: function data() {
    var _bewertungTypOptions, _datenshutzTypOptions, _zvTypOptions;

    return {
      BewertungTyp: BewertungSammlungTypValues,
      bewertungTypOptions: (_bewertungTypOptions = {}, _defineProperty(_bewertungTypOptions, BewertungSammlungTypValues.Klassifizierung, 'Aus Klassifizierung'), _defineProperty(_bewertungTypOptions, BewertungSammlungTypValues.Stichworte, 'Aus Stichworten'), _defineProperty(_bewertungTypOptions, BewertungSammlungTypValues.Extraseiten, 'Aus Extra-Seiten'), _defineProperty(_bewertungTypOptions, BewertungSammlungTypValues.None, 'Keine Bewertung'), _bewertungTypOptions),
      DatenschutzTyp: DatenschutzSammlungTypValues,
      datenshutzTypOptions: (_datenshutzTypOptions = {}, _defineProperty(_datenshutzTypOptions, DatenschutzSammlungTypValues.Stichworte, 'Aus Stichworten'), _defineProperty(_datenshutzTypOptions, DatenschutzSammlungTypValues.Extraseiten, 'Aus Extra-Seiten'), _defineProperty(_datenshutzTypOptions, DatenschutzSammlungTypValues.None, 'Keine Datenschutzerklärung'), _datenshutzTypOptions),
      ZvTyp: ExtraSeitenStichwortSammlungTypValues,
      zvTypOptions: (_zvTypOptions = {}, _defineProperty(_zvTypOptions, ExtraSeitenStichwortSammlungTypValues.Stichworte, 'Aus Stichworten'), _defineProperty(_zvTypOptions, ExtraSeitenStichwortSammlungTypValues.Extraseiten, 'Aus Extra-Seiten'), _zvTypOptions),
      rechenzentrumTab: null,
      rules: rules
    };
  },
  computed: {
    zvClass: function zvClass() {
      return this.hasPraxisReport ? 'zv-half-span' : 'zv-full-span';
    },
    userToExecuteAs: {
      get: function get() {
        var _a, _b;

        return (_b = (_a = editSettingsStore.getters) === null || _a === void 0 ? void 0 : _a.praxisKPIReport) === null || _b === void 0 ? void 0 : _b.userToExecuteAs;
      },
      set: function set(value) {
        if (this.rules.email(value) === true) {
          editSettingsRootStore.commit.deleteError({
            tabName: 'Datensammlung',
            key: 'userToExecuteAs'
          });
        } else {
          editSettingsRootStore.commit.addError({
            tabName: 'Datensammlung',
            key: 'userToExecuteAs',
            error: 'E-Mail-Adresse in Praxis-KPI-Report falsch.'
          });
          this.$log.debug('Keine gültige E-Mail-Adresse!');
        }

        editSettingsStore.commit.setPraxisKpiReportUser(value);
      }
    },
    emailRecipients: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.praxisKPIReport) === null || _a === void 0 ? void 0 : _a.emailRecipients;
      },
      set: function set(value) {
        if (this.rules.emailDelimited(value) === true) {
          editSettingsRootStore.commit.deleteError({
            tabName: 'Datensammlung',
            key: 'emailRecipients'
          });
        } else {
          editSettingsRootStore.commit.addError({
            tabName: 'Datensammlung',
            key: 'emailRecipients',
            error: 'Liste der E-Mail-Adressen in Praxis-KPI-Report falsch.'
          });
          this.$log.debug('Keine gültige E-Mail-Adresse!');
        }

        editSettingsStore.commit.setPraxisKpiReportMailRecipient(value);
      }
    },
    zvTyp: {
      get: function get() {
        return editSettingsStore.getters.zvTyp;
      },
      set: function set(value) {
        editSettingsStore.commit.setZusatzversicherungTyp(value);
      }
    },
    zvExtraSeiten: {
      get: function get() {
        return editSettingsStore.getters.zvExtraSeiten;
      },
      set: function set(value) {
        editSettingsStore.commit.setZusatzversicherungExtraseite(value);
      }
    },
    bewertungTyp: {
      get: function get() {
        return editSettingsStore.getters.bewertungTyp;
      },
      set: function set(value) {
        if (value) {
          editSettingsStore.commit.setBewertungTyp(value);
        }
      }
    },
    bewertungStichworte: {
      get: function get() {
        return editSettingsStore.getters.bewertungStichworte;
      },
      set: function set(value) {
        if (value) {
          if (this.rules.regex(value) === true) {
            editSettingsRootStore.commit.deleteError({
              tabName: 'Datensammlung',
              key: 'bewertungStichworte'
            });
            editSettingsStore.commit.setBewertungStichWorteRegex(value);
          } else {
            editSettingsRootStore.commit.addError({
              tabName: 'Datensammlung',
              key: 'bewertungStichworte',
              error: 'Regulärer Ausdruck in Bewertung falsch.'
            });
            this.$log.debug('Kein gültiger Regex!');
          }
        }
      }
    },
    bewertungExtraseite: {
      get: function get() {
        return editSettingsStore.getters.bewertungExtraseite;
      },
      set: function set(value) {
        editSettingsStore.commit.setBewertungExtraseite(value);
      }
    },
    patextrapagefields: function patextrapagefields() {
      return staticDataMetricsStore.state.params.patextrapagefields;
    },
    datenschutzTyp: {
      get: function get() {
        return editSettingsStore.getters.datenschutzTyp;
      },
      set: function set(value) {
        editSettingsStore.commit.setDatenschutzTyp(value);
      }
    },
    datenschutzStichworte: {
      get: function get() {
        return editSettingsStore.getters.datenschutzStichworte;
      },
      set: function set(value) {
        editSettingsStore.commit.setDatenschutzStichWorteRegex(value);
      }
    },
    datenschutzExtraseite: {
      get: function get() {
        return editSettingsStore.getters.datenschutzExtraseite;
      },
      set: function set(value) {
        editSettingsStore.commit.setDatenschutzExtraseite(value);
      }
    },
    doctolibActive: {
      get: function get() {
        return editSettingsStore.state.editSettings.doctolib.active;
      },
      set: function set(value) {
        editSettingsStore.commit.setDoctolibActive(value);
      }
    },
    doctolibKey: {
      get: function get() {
        return editSettingsStore.state.editSettings.doctolib.key;
      },
      set: function set(value) {
        editSettingsStore.commit.setDoctolibKey(value);
      }
    },
    doctolibSecretKey: {
      get: function get() {
        return editSettingsStore.state.editSettings.doctolib.secretKey;
      },
      set: function set(value) {
        editSettingsStore.commit.setDoctolibSecretKey(value);
      }
    },
    doctolibActiveLabel: function doctolibActiveLabel() {
      return this.doctolibActive ? 'Aktiv' : 'Inaktiv';
    },
    stichworte: function stichworte() {
      return staticDataMetricsStore.state.params.stichworte;
    },
    hasPraxisReport: function hasPraxisReport() {
      return authStore.getters.hasPraxisReport;
    },
    hasFactoring: function hasFactoring() {
      return authStore.getters.hasFactoring;
    },
    hasDoctosync: function hasDoctosync() {
      return authStore.getters.hasDoctosync;
    },
    isBetaCustomer: function isBetaCustomer() {
      return authStore.getters.isBetaCustomer;
    },
    isCharly: function isCharly() {
      return editSettingsStore.getters.isCharly;
    },
    rechenzentrumTabs: function rechenzentrumTabs() {
      if (this.isCharly && this.hasFactoring) {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }, {
          name: 'Aus Extra-Seiten',
          component: 'RechenzentrumExtraSeiten'
        }, {
          name: 'Aus Factoring',
          component: 'RechenzentrumFactoring'
        }];
      } else if (this.isCharly && !this.hasFactoring) {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }, {
          name: 'Aus Extra-Seiten',
          component: 'RechenzentrumExtraSeiten'
        }];
      } else if (!this.isCharly && this.hasFactoring) {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }, {
          name: 'Aus Factoring',
          component: 'RechenzentrumFactoring'
        }];
      } else {
        return [{
          name: 'Aus Stichworten',
          component: 'RechenzentrumStichworte'
        }];
      }
    }
  }
});