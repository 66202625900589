import { defineComponent } from 'vue';
import { editFactoringStore } from '@/state/settings/editFactoringStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {};
  },
  computed: {
    rechenzentren: function rechenzentren() {
      return editFactoringStore.getters.clientRechenZentrenInfo;
    }
  },
  methods: {
    updateRechenzentrum: function updateRechenzentrum(value, id) {
      if (value) {
        editSettingsStore.commit.addRechenzentrumReihenfolge(id);
      } else {
        editSettingsStore.commit.deleteRechenzentrumReihenfolge(id);
      }
    }
  }
});