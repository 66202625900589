import { IRoseAGGridColumn } from '@rose/common-ui';
import { IStichwortParam } from '@rose/types';
import { IDeleteButtonCell, IInputCell } from '@/components/cells/cellInterfaces';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';

export function settingsDiversZusatzversicherungColumnsDefs(): IRoseAGGridColumn<string>[] {
  return [
    {
      headerName: 'Stichwort',
      sortable: true,
      cellRenderer: 'StichwortCell',
      valueGetter: params => params.data && staticDataMetricsStore.state.params.stichworteMap?.[params.data],
      sort: 'asc',
      width: 250,
    },
    {
      headerName: 'Anzeigename',
      valueGetter: params =>
        params.data && { value: editSettingsStore.getters.zvStichworte?.[params.data], key: params.data },
      cellRenderer: 'InputCell',
      cellRendererParams: {
        context: {
          clicked(field: { key: string; value: string }) {
            editSettingsStore.commit.setZvStichwortText({ name: field.key, text: field.value });
          },
        },
      } as IInputCell,
      resizable: false,
      sortable: true,
      flex: 1,
    },
    {
      headerName: '',
      cellRenderer: 'DeleteButtonCell',
      cellStyle: { justifyContent: 'end' },
      resizable: false,
      width: 50,
      cellRendererParams: {
        context: {
          clicked(field: { row: IStichwortParam['name'] }) {
            editSettingsStore.commit.deleteZvStichwort(field.row);
          },
        },
      } as IDeleteButtonCell<IStichwortParam['name']>,
    },
  ];
}
