import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsDiversRechenzentrumColumnsDefs } from '@/components/columns/settings/diversRechenzentrum';
import StichwortChip from '@/components/StichwortChip.vue';
import { find } from 'lodash';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    StichwortChip: StichwortChip
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: null,
      gridApi: null,
      gridColumnApi: null,
      overlayLoadingTemplate: null,
      overlayNoRowsTemplate: null,
      columnDefs: settingsDiversRechenzentrumColumnsDefs(),
      stichwortSelected: ''
    };
  },
  computed: {
    rzStichworte: function rzStichworte() {
      return editSettingsStore.getters.rzStichworte;
    },
    stichworteAvailable: function stichworteAvailable() {
      return editSettingsStore.getters.rzStichworteAvailable;
    },
    isInactive: function isInactive() {
      return !!find(editSettingsStore.getters.rechenzentrumReihenfolgeInactive, function (x) {
        return x.name === 'stichworte';
      });
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      rowHeight: 50,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true
      },
      rowSelection: 'single',
      suppressCellFocus: true
    };
  },
  mounted: function mounted() {
    if (this.gridOptions.api) {
      this.gridApi = this.gridOptions.api;
    }

    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridOptions.api.setDomLayout('autoHeight');
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      console.log('onCellClicked', event);
    },
    addRZStichwort: function addRZStichwort() {
      if (this.stichwortSelected) {
        editSettingsStore.commit.addRZStichwort(this.stichwortSelected);
        this.stichwortSelected = null;
      }
    }
  }
});