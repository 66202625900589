import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {};
  },
  computed: {
    rechzentrumReihenfolgeActive: {
      get: function get() {
        return editSettingsStore.getters.rechzentrumReihenfolgeOrdered;
      },
      set: function set(value) {
        editSettingsStore.commit.updateRechenzentrumReihenfolge(value.map(function (v) {
          return v.name;
        }));
      }
    },
    rechzentrumReihenfolgeInactive: {
      get: function get() {
        return editSettingsStore.getters.rechenzentrumReihenfolgeInactive;
      },
      set: function set(value) {
        console.log(value);
      }
    },
    rechzentrumAvaTageGueltig: {
      get: function get() {
        return editSettingsStore.getters.rechzentrumAvaTageGueltig;
      },
      set: function set(value) {
        editSettingsStore.commit.setRechzentrumAvaTageGueltig(value);
      }
    },
    rechzentrumEweMonateGueltig: {
      get: function get() {
        return editSettingsStore.getters.rechzentrumEweMonateGueltig;
      },
      set: function set(value) {
        editSettingsStore.commit.setRechzentrumEweMonateGueltig(value);
      }
    },
    rechzentrumMinderjaehrigeIgnorieren: {
      get: function get() {
        return editSettingsStore.getters.rechzentrumMinderjaehrigeIgnorieren;
      },
      set: function set(value) {
        editSettingsStore.commit.setRechzentrumMinderjaehrigeIgnorieren(value);
      }
    }
  },
  methods: {
    getNiceName: function getNiceName(key) {
      switch (key) {
        case "extraseiten":
          return "Extra-Seiten";

        case "stichworte":
          return "Stichworte";

        default:
          return key;
      }
    },
    log: function log(msg) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      console.log(msg);
    }
  }
});